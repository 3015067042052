:root {
    --green: #00A36C;
    --white: #F5F5F5;
    --background: #282C34;
}

html {
    min-height: 100%;
    background-color: var(--background);
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    text-align: justify;
}

#root {
    background-color: var(--background);
}

.page {
    margin-top: 7em;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
}

.row {
    /* padding-top: 3em; */
}

.col {
    
}

.img {
    /* margin: auto;
    display: block; */
}

.btn {
    text-align: center;
}

.link {
    color: var(--green);
}


.logo {
    height: 50px;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .logo {
        animation: logo-spin infinite 20s linear;
    }
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
